import { createRouter, createWebHistory } from "vue-router";

import Growth2022 from "./components/contest/Growth2022.vue";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: "/", redirect: "/home" },
    {
      path: "/home",
      component: () => import("./pages/HomePage.vue"),
      name: "home",
    },
    {
      path: "/about-us",
      component: () => import("./pages/AboutUsPage.vue"),
      name: "about-us",
    },
    {
      path: "/creators",
      component: () => import("./pages/CreatorPage.vue"),
      name: "creators",
    },
    {
      path: "/plans",
      component: () => import("./pages/PricingPage.vue"),
      name: "plans",
    },
    {
      path: "/contest",
      component: () => import("./pages/ContestPage.vue"),
      name: "contest",
      children: [
        {
          path: "growth2022",
          component: Growth2022,
          name: "growth2022",
        },
      ],
    },
    {
      path: "/well-vrsd",
      component: () => import("./pages/NewsletterPage.vue"),
      name: "newsletter",
    },
    {
      path: "/terms",
      component: () => import("./pages/TermsPage.vue"),
      name: "terms",
    },
    {
      path: "/privacy",
      component: () => import("./pages/PrivacyPage.vue"),
      name: "privacy",
    },
    {
      path: "/contact",
      component: () => import("./pages/ContactPage.vue"),
      name: "contact",
    },
    {
      path: "/feed",
      component: () => import("./pages/FeedPage.vue"),
      name: "feed",
    },
    { path: "/fintrivia",
      component: () => import("./pages/FeedPage.vue"),
      name: "fintrivia",
    },
    {
      path: "/showdown-rules",
      component: () => import("./pages/ShowdownRulesPage.vue"),
      name: "showdown-rules",
    },
    {
      path: "/fintrivia-rules",
      component: () => import("./pages/FinTriviaRulesPage.vue"),
      name: "fintrivia-rules",
    },
    { path: "/:notFound(.*)", redirect: "/home" },
  ],
  linkActiveClass: "active",
});

export default router;
